import styled from "styled-components"

const Table = styled.table`
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
`

const TableEqualColumns = styled(Table)`
  table-layout: fixed;
`

const Std = styled.td`
  border: 1px solid #ccc;
  border-bottom: ${p => (p.noBottom ? "none" : "1px solid #ccc")};
  padding: 10px 12px;
  vertical-align: top;
  text-align: left;
`

const Sth = styled.th`
  background-color: ${p => p.theme.colors.grey_light};
  border: 1px solid #ccc;
  padding: 10px 12px;
  text-align: left;
`
export { Table, TableEqualColumns, Std, Sth }
