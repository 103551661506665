export const CalculateResults = values => {
  let results = {
    numerical_assessment: {},
    veral_assessment: {},
  }

  let score = 0

  const MarkQuestion = (section, question, correctAnswer) => {
    if (values[section][question] === correctAnswer) {
      results[section][question] = "Correct"
      score++
    } else {
      results[section][question] = "Incorrect"
    }
  }
  // Numberical
  MarkQuestion("numerical_assessment", "question_1", "3/4")
  MarkQuestion("numerical_assessment", "question_2", "1400mm")
  MarkQuestion("numerical_assessment", "question_3", "50%")
  MarkQuestion("numerical_assessment", "question_4", "47 minutes")
  MarkQuestion("numerical_assessment", "question_5", "25")
  MarkQuestion("numerical_assessment", "question_6", "£26.04")
  MarkQuestion("numerical_assessment", "question_7", "£35.70")
  MarkQuestion("numerical_assessment", "question_8", "10 minutes")
  MarkQuestion("numerical_assessment", "question_9", "3.5 hours")
  MarkQuestion("numerical_assessment", "question_10", "10")
  MarkQuestion("numerical_assessment", "question_11", "7")
  MarkQuestion("numerical_assessment", "question_12", "£3.92")
  MarkQuestion("numerical_assessment", "question_13", "637")
  MarkQuestion("numerical_assessment", "question_14", "5")
  MarkQuestion("numerical_assessment", "question_15", "14/29")
  // Verbal
  MarkQuestion(
    "veral_assessment",
    "question_1",
    "They are going on holiday to Spain."
  )
  MarkQuestion("veral_assessment", "question_2", "Is")
  MarkQuestion(
    "veral_assessment",
    "question_3",
    "Cars, buses, and vans, need a tax disc."
  )
  MarkQuestion("veral_assessment", "question_4", "Menacingly")

  results.score = score + "/19" + (score > 15 ? " (Passed)" : " (Failed)")

  return results
}
