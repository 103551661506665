import * as Yup from "yup"
import { standardRequiredText } from "../../constants"

export const validationSchema = Yup.object().shape({
  numerical_assessment: Yup.object().shape({
    question_1: Yup.string().required(standardRequiredText),
    question_2: Yup.string().required(standardRequiredText),
    question_3: Yup.string().required(standardRequiredText),
    question_4: Yup.string().required(standardRequiredText),
    question_5: Yup.string().required(standardRequiredText),
    question_6: Yup.string().required(standardRequiredText),
    question_7: Yup.string().required(standardRequiredText),
    question_8: Yup.string().required(standardRequiredText),
    question_9: Yup.string().required(standardRequiredText),
    question_10: Yup.string().required(standardRequiredText),
    question_11: Yup.string().required(standardRequiredText),
    question_12: Yup.string().required(standardRequiredText),
    question_13: Yup.string().required(standardRequiredText),
    question_14: Yup.string().required(standardRequiredText),
    question_15: Yup.string().required(standardRequiredText),
  }),
  veral_assessment: Yup.object().shape({
    question_1: Yup.string().required(standardRequiredText),
    question_2: Yup.string().required(standardRequiredText),
    question_3: Yup.string().required(standardRequiredText),
    question_4: Yup.string().required(standardRequiredText),
  }),
  full_name: Yup.string().required(standardRequiredText),
  understands_absence_procedure: Yup.number().oneOf([1], standardRequiredText),
})
