import React from "react"
import SButton from "../button"
import SignaturePad from "react-signature-canvas"
import SignatureCanvas from "react-signature-canvas"
import { Label } from "../form"

const Signature = ({ sigCanvas, signatureDrawn, setSignatureDrawn }) => {
  const clear = () => {
    sigCanvas.current.clear(), setSignatureDrawn(false)
  }

  return (
    <section>
      <p>
        <b>
          Please sign to confirm you have read and understood the contents of
          this form
        </b>
      </p>
      <Label>Your signature</Label>
      {/* <SignaturePad
        penColor="black"
        backgroundColor="rgba(246,248,251,100)"
        name="signature"
        ref={sigCanvas}
        canvasProps={{
          className: "sigCanvas",
        }}
        onBegin={() => (signatureDrawn ? null : setSignatureDrawn(true))}
      /> */}
      <SignatureCanvas
        penColor="black"
        canvasProps={{ className: "sigCanvas" }}
        ref={sigCanvas}
        backgroundColor="rgba(246,248,251,100)"
        name="signature"
        onBegin={() => (signatureDrawn ? null : setSignatureDrawn(true))}
      />
      <SButton type="button" name="clear-signature" onClick={clear} red={true}>
        Clear Signature
      </SButton>
    </section>
  )
}

export default Signature
