export const initialValues = {
  numerical_assessment: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
    question_5: "",
    question_6: "",
    question_7: "",
    question_8: "",
    question_9: "",
    question_10: "",
    question_11: "",
    question_12: "",
    question_13: "",
    question_14: "",
    question_15: "",
  },
  veral_assessment: {
    question_1: "",
    question_2: "",
    question_3: "",
    question_4: "",
  },
  full_name: "",
  understands_absence_procedure: 0,
}
