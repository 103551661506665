import React, { useRef, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { dataURItoBlob } from "../../utils/form-data"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import ParagonLogo from "../../assets/images/logos/paragon-meed-logo.jpg"
import { TableEqualColumns, Std, Sth } from "../../style/tables"
import {
  LCard,
  FieldSet,
  Col2,
  CardGrey,
  HeaderWithLogo,
} from "../../style/containers"

import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import Form from "../../components/form/FormHelper"
import { FormikTabs, FormikInput, FormikCheckbox } from "../../components/form"
import {
  initialValues,
  validationSchema,
  CalculateResults,
} from "../../components/ParagonNewStarter"

const ParagonNewStarterForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    values.results = CalculateResults(values)

    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Paragon New Starter Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>Paragon New Starter Form</h1>
                <img src={ParagonLogo} alt="Unipres Logo" />
              </HeaderWithLogo>
              <h2>Numberical and Verbal Assessment</h2>
              <b>
                This assessment is designed to test your basic numeracy skills
                as part of the recruitment and selection process at Paragon
                Group UK Ltd.
              </b>
              <br />
              <br />
              <p>
                Both tests consist of 20 questions, which you must answer within
                the specified time limit of 25 minutes per test. Overall these
                two tests have been designed for you to demonstrate your skills
                at:
              </p>
              <TableEqualColumns>
                <thead>
                  <tr>
                    <Sth>Numberical</Sth>
                    <Sth>Verbal</Sth>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Std>
                      <ul>
                        <li>Addition and subtraction</li>
                        <li>Division and multiplication</li>
                        <li>Use of decimals fractions</li>
                        <li>Percentages</li>
                        <li>The use of a calculator</li>
                      </ul>
                    </Std>
                    <Std>
                      <ul>
                        <li>Spelling</li>
                        <li>Punctuation</li>
                        <li>Grammar</li>
                      </ul>
                    </Std>
                  </tr>
                </tbody>
              </TableEqualColumns>
              <br />
              <p>
                Please feel free to use a pen, paper and calculator provided to
                help you calculate the answers to these questions.
              </p>
              <b>Good Luck.</b>
              <FieldSet>
                <h2>Numerical Ability Assessment</h2>
                <h4>Question 1</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_1"
                  label="How do you write 0.75 as a fraction?"
                  tabs={[
                    { name: "1/2", label: "1/2" },
                    { name: "1/4", label: "1/4" },
                    { name: "3/4", label: "3/4" },
                    { name: "7/5", label: "7/5" },
                  ]}
                />
                <h4>Question 2</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_2"
                  label="1.4 metres is the same as:"
                  tabs={[
                    { name: "1400mm", label: "1400mm" },
                    { name: "140mm", label: "140mm" },
                    { name: "1400cm", label: "1400cm" },
                    { name: "14cm", label: "14cm" },
                  ]}
                />
                <h4>Question 3</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_3"
                  label="A bag contains 6 blue balls, 4 green and 2 red. If you put your hand in and picked a ball at random what are the chances it is blue?"
                  tabs={[
                    { name: "45%", label: "40%" },
                    { name: "60%", label: "60%" },
                    { name: "50%", label: "50%" },
                    { name: "33.3%", label: "33.3%" },
                  ]}
                />
                <h4>Question 4</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_4"
                  label="The bus is scheduled to leave at 16.24pm. It doesn’t actually leave until 7 minutes later. You get off the bus at 17.17pm. How long was the journey?"
                  tabs={[
                    { name: "53 minutes", label: "53 minutes" },
                    { name: "46 minutes", label: "46 minutes" },
                    { name: "47 minutes", label: "47 minutes" },
                    { name: "54 minutes", label: "54 minutes" },
                  ]}
                />
                <h4>Question 5</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_5"
                  label="What is the next number in the series 1,4,9,16?"
                  tabs={[
                    { name: "25", label: "25" },
                    { name: "20", label: "20" },
                    { name: "21", label: "21" },
                    { name: "33", label: "33" },
                  ]}
                />
                <h4>Question 6</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_6"
                  label="If Rob earns £6.51 per hour. How much does he earn in 4 hours? "
                  tabs={[
                    { name: "£26.94", label: "£26.94" },
                    { name: "£25.09", label: "£25.09" },
                    { name: "£26.04", label: "£26.04" },
                    { name: "£18.56", label: "£18.56" },
                  ]}
                />
                <h4>Question 7</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_7"
                  label="A shirt normally costs £42. If you receive 15% discount, how much change will you get?"
                  tabs={[
                    { name: "£35.70", label: "£35.70" },
                    { name: "£23.00", label: "£23.00" },
                    { name: "£8.00", label: "£8.00" },
                    { name: "£14.30", label: "£14.30" },
                  ]}
                />
                <h4>Question 8</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_8"
                  label="It takes 40 minutes for 2 men to dig the garden. How long will it take 8 men?"
                  tabs={[
                    { name: "5 minutes", label: "5 minutes" },
                    { name: "10 minutes", label: "10 minutes" },
                    { name: "20 minutes", label: "20 minutes" },
                    { name: "15 minutes", label: "15 minutes" },
                  ]}
                />
                <h4>Question 9</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_9"
                  label="A car travels for 90 miles at an average speed of 60mph. Due to traffic it then reduces its average speed to 30mph for a further 60 miles. How long does the journey take?"
                  tabs={[
                    { name: "2.5 hours", label: "2.5 hours" },
                    { name: "2 hours", label: "2 hours" },
                    { name: "3.5 hours", label: "3.5 hours" },
                    { name: "3 hours ", label: "3 hours " },
                  ]}
                />
                <h4>Question 10</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_10"
                  label="What is the missing number? 83-17=56+?"
                  tabs={[
                    { name: "6", label: "6" },
                    { name: "10", label: "10" },
                    { name: "16", label: "16" },
                    { name: "20", label: "20" },
                  ]}
                />
                <h4>Question 11</h4>
                <FormikInput
                  label="What is 3.5% of 200?"
                  name="numerical_assessment.question_11"
                />
                <h4>Question 12</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_12"
                  label="If a loaf of bread costs £1.52 how much change would you get from £10 if you buy 4 loaves?"
                  tabs={[
                    { name: "£5.08", label: "£5.08" },
                    { name: "£3.92", label: "£3.92" },
                    { name: "£6.25", label: "£6.25" },
                    { name: "£5.52", label: "£5.52" },
                  ]}
                />
                <h4>Question 13</h4>
                <FormikInput
                  label="What is 7/8 of 728?"
                  name="numerical_assessment.question_13"
                />
                <h4>Question 14</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="numerical_assessment.question_14"
                  label="Fred starts with 6 marbles, Tony starts with 2, and Katie starts with 4 marbles. Fred gives 1/3 of his marbles to Katie who then gives ½ of her new total to Tony. How many marbles has Tony now got? "
                  tabs={[
                    { name: "6", label: "6" },
                    { name: "2", label: "2" },
                    { name: "4", label: "4" },
                    { name: "5", label: "5" },
                  ]}
                />
                <h4>Question 15</h4>
                <FormikInput
                  label="Please simplify this fraction to its lowest terms 280/580"
                  name="numerical_assessment.question_15"
                />
                <h2>Verbal Ability Assessment</h2>
                <h4>Question 1</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="veral_assessment.question_1"
                  label="Which Sentence is correct?"
                  tabs={[
                    {
                      name: "They going on holiday to Spain.",
                      label: "They going on holiday to Spain.",
                    },
                    {
                      name: "They is going on holiday to Spain.",
                      label: "They is going on holiday to Spain.",
                    },
                    {
                      name: "They are going on holiday to Spain.",
                      label: "They are going on holiday to Spain.",
                    },
                    {
                      name: "They are go on holiday to Spain.",
                      label: "They are go on holiday to Spain.",
                    },
                  ]}
                />
                <h4>Question 2</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="veral_assessment.question_2"
                  label="Which word is missing from this sentence? ‘He _______ working on the night shift next week.’"
                  tabs={[
                    { name: "Is", label: "Is" },
                    { name: "Are", label: "Are" },
                    { name: "Will", label: "Will" },
                    { name: "Was", label: "Was" },
                  ]}
                />
                <h4>Question 3</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="veral_assessment.question_3"
                  label="Which of these sentences has the right number of commas, with each comma in the right place?"
                  tabs={[
                    {
                      name: "Cars, buses, and vans, need a tax disc.",
                      label: "Cars, buses, and vans, need a tax disc.",
                    },
                    {
                      name: "Cars, buses and vans need a tax disc.",
                      label: "Cars, buses and vans need a tax disc.",
                    },
                  ]}
                />
                <h4>Question 4</h4>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="veral_assessment.question_4"
                  label="Which of these adverbs is the most suitable to complete the sentence: The dog growled ________."
                  tabs={[
                    { name: "Neatly", label: "Neatly" },
                    { name: "Timidly", label: "Timidly" },
                    { name: "Happily", label: "Happily" },
                    { name: "Menacingly", label: "Menacingly" },
                  ]}
                />
              </FieldSet>
              <h2>Paragon New Starters</h2>
              <b>
                When you use your access card you must make sure it is handed
                into either the Paragon HR department or the Pinpoint Office
                before leaving or you could be charged £20 if it is not received
                within 48 hours from your last shift.
              </b>
              <br />
              <br />
              <p>
                Report to the Gatehouse at Paragon on your first day. You will
                be issued with a pass.
              </p>
              <p>
                Ensure that you remember your pass number and code (found on the
                back of your pass). You will be given a locker number. Once you
                receive your pass, pass code and locker key, you will be told to
                enter the building.{" "}
                <b>
                  You will need to return your pass daily and collect from the
                  Gatehouse DAILY.
                </b>
              </p>
              <ol>
                <li>
                  <p>Go through the employee entrance</p>
                </li>
                <li>
                  <p>
                    Go into the second right door, pick up an EMPTY clock card,
                    write your name on this and clock in using the machine to
                    the left hand side. Then LEAVE your clock card on the table.
                  </p>
                </li>
                <li>
                  <p>
                    <b>
                      Remember to always clock in and clock out using the card
                      you write out on a MONDAY/START OF THE WEEK.
                    </b>
                  </p>
                </li>
                <li>
                  <p>
                    At the end of the week/last working shift, YOU MUST place
                    your clock card for that week into the PINPOINT clock card
                    holder, ready for collection.
                  </p>
                </li>
                <li>
                  <p>You must complete a new clock card each week.</p>
                </li>
              </ol>
              <p>
                Please note, if you do not adhere to the above procedure, and
                your clock card is not in the PINPOINT clock card holder, this
                may affect you being paid on time.
              </p>
              <h2>Paragon Absence Procedure</h2>
              <p>
                The absence procedure outlined below must be followed by all Pin
                Point employees.
              </p>
              <p>
                If you are unable to attend Paragon for your shift, you must
                call:
              </p>
              <Col2>
                <CardGrey>
                  <b>Lauren Manson</b>
                  <span>
                    Mobile: <a href="tel: 07889537319">07889 537319</a>
                  </span>
                  <span>
                    Tel: <a href="tel: 01912615205">0191 2615205</a>
                  </span>
                </CardGrey>
              </Col2>
              <p>
                <b>
                  You MUST call at least 1 hour prior to the start of your shift
                  and explain the reasons for your absence
                </b>{" "}
                and when you will return to work. If you are absent for more
                than one day
              </p>
              <p>
                This standard absence procedure and MUST be adhered to at all
                times. Failure to do so may jeopardise your position at Lear.
              </p>
              <FormikInput
                name="full_name"
                type="text"
                label="Your full name"
              />
              <FormikCheckbox
                bold={true}
                label="I have read and understand the Paragon Absence Procedure"
                name="understands_absence_procedure"
              />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default ParagonNewStarterForm
